<script setup lang="ts">
// convenience component for links to docs

interface Props {
  to: string
}

const props = withDefaults(defineProps<Props>(), {})

const BASE_URL = "https://docs.streamfold.com/"

const docsUrl = computed(() => BASE_URL + props.to)
</script>

<template>
  <NuxtLink :to="docsUrl" target="_blank">
    <slot></slot>
  </NuxtLink>
</template>
